import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { DAEMON } from '@dbh/redux-extra';
import { makeSelectLocale, makeSelectCountry, COUNTRY_ANDOR_LOCALE_CHANGED } from '@dbh/routing-redux';
import { SSR_STARTED } from '@dbh/ssr-data-redux';
import { Map } from 'immutable';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import { ApiRequestError } from '@dbh/request-errors';
import '@dbh/decorate-webcontrols-list';
import { makeSelectCountriesLoaded, countriesLoaded, countriesLoadingError } from './index.js';
import '@dbh/reselect-extra';
import 'reselect';
import '@dbh/lodash-extra';
import 'react-immutable-proptypes';
import '@dbh/with-conforms-to-for-production-www';
import 'prop-types';

function*getCountries(a){const{locale:b,country:c}=a;let d=b,e=c;d||(d=yield select(makeSelectLocale())),e||(e=yield select(makeSelectCountry()));// Cache.
const f=yield select(makeSelectCountriesLoaded());if(f)return;const g={queryParameters:Map({webEnabled:!0})},h=yield select(makeSelectSagaRequestOptions(API_IDS.countries)),i=yield select(makeSelectApiUrl(API_IDS.countries,g));// API request.
try{const a=yield call(request,i,h),{emptyResponse:b,apiResponse:c,requestAttributes:f}=a;if(b)throw new ApiRequestError(a);let g=c["hydra:member"];const j={countries:g,requestAttributes:f,locale:d,country:e};yield put(countriesLoaded(j));}catch(a){// We throw and let `serverEntry` handle the rendering of an error message,
// since this is a particularly "bad" failure and we can't recover and
// render our front end.
throw yield put(countriesLoadingError(a)),a}}

function*rootSaga(){yield all([takeLatest(COUNTRY_ANDOR_LOCALE_CHANGED,getCountries),// Note: we use `takeLatest` since `take` doesn't work here, even though
// it wouldn't be needed because there won't (correctly) be a second
// `SSR_STARTED` event.
takeLatest(SSR_STARTED,getCountries)]);}const injectedSagaConfiguration={key:"countries",mode:DAEMON};

export { rootSaga as default, injectedSagaConfiguration };
